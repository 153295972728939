<nav #navbar class="navbar active">
    <div class="container-fluid">
        <div class="navbar-header">
            <a href="#" onClick="return false;" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false"></a>
            <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
            <a class="navbar-brand" routerLink={{homePage}}>
                <img src="assets/images/logo.png" alt="" style="width: 35px;" />
                <span class="logo-name">ORRCA</span>
            </a>
        </div>
        <div class="collapse navbar-collapse" id="navbar-collapse">
            <ul class="pull-left collapse-menu-icon">
                <li>
                    <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
                </li>
            </ul>
            <ul class="nav navbar-nav navbar-right">
                <!-- Full Screen Button -->
                <li class="fullscreen">
                    <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
                </li>
                <!-- #END# Full Screen Button -->
                <!-- #START# Notifications-->
                <!-- <li class="dropdown">
                    <button mat-icon-button data-toggle="dropdown" class=" nav-notification-icons">
            <mat-icon>notifications_none</mat-icon>
            <span class="label-count bg-orange"></span>
          </button>
                    <ul class="dropdown-menu pullDown">
                        <li class="header">NOTIFICATIONS</li>
                        <li class="body">
                            <ul class="menu" style="position: relative; max-width: 600px; max-height: 200px;" [perfectScrollbar]>
                                <li>
                                    <a href="#" *ngFor="let notification of notifications" onClick="return false;">
                                        <span class="table-img msg-user">
                      <img src="{{notification.userImg}}" alt="">
                    </span>
                                        <span class="menu-info">
                      <span class="menu-title">{{notification.userName}}</span>
                                        <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.time}}
                      </span>
                                        <span class="menu-desc">{{notification.message}}</span>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="footer">
                            <a href="#" onClick="return false;">View All Notifications</a>
                        </li>
                    </ul>
                </li> -->
                <!-- #END# Notifications-->
                <li class="dropdown user_profile">
                    <a href="#" onClick="return false;" data-toggle="dropdown" role="button" class="pt-0">
                        <img src={{userImg}} class="rounded-circle" width="32" height="32" alt="User">
                    </a>
                    <ul class="dropdown-menu pullDown">
                        <li class="body">
                            <ul class="user_dw_menu">
                                <!-- <li>
                                    <a href="#" onClick="return false;">
                                        <mat-icon aria-hidden="false" class="mr-2">person</mat-icon>Profile
                                    </a>
                                </li>
                                <li>
                                    <a href="#" onClick="return false;">
                                        <mat-icon aria-hidden="false" class="mr-2">feedback</mat-icon>Feedback
                                    </a>
                                </li>-->
                                <li *ngIf="CheckRole()">
                                    <a routerLink='/profile/reset-password' onClick="return false;">
                                        <mat-icon aria-hidden="false" class="mr-2">vpn_key</mat-icon>Reset Password
                                    </a>
                                </li>
                                <li>
                                    <a (click)="logout()">
                                        <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>Logout
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <!-- #END# Tasks -->
                <!-- <li class="pull-right">
          <button mat-icon-button (click)="toggleRightSidebar()" class="nav-notification-icons js-right-sidebar">
            <mat-icon id="settingBtn">settings</mat-icon>
          </button>
        </li> -->
            </ul>
        </div>
    </div>
</nav>