<div class="addContainer">
    <div class="modalHeader">
        <div class="editRowModal">
            <div class="modalHeader clearfix">
                <div class="modal-about">
                    <div class="font-weight-bold p-t-5 font-17">
                        {{dialogTitle}}</div>
                </div>
            </div>
        </div>
        <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <form class="register-form m-4" [formGroup]="statusForm" (ngSubmit)="submit">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                    <mat-form-field appearance="fill">
                        <mat-label>Status</mat-label>
                        <select matNativeControl [formControl]="nativeSelectFormControl" formControlName="status_edit" required>
                            <option value="Cancelled" > Cancelled </option>
                            <option value="Pending" > Pending </option>
                            <option value="Confirmed" > Confirmed </option>
                            <option value="tentative" > Tentative </option>
                            <option value="Acknowledged" > Acknowledged </option>
                        </select>
                      </mat-form-field>
                </div>
            </div>
            <div class="row" hidden>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                        <mat-label>Appointment Id</mat-label>
                        <input matInput formControlName="AppId" value="{{appointmentId}} " required>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                    <div class="example-button-row">
                        <button mat-raised-button color="primary" [type]="submit" [mat-dialog-close]="1" (click)="confirmAdd()">Save</button>
                        <button mat-raised-button color="warn" (click)="onNoClick()" tabindex="-1">Cancel</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
